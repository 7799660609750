<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Service List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 mt-2 text-right">
            <va-button
              icon="fa fa-plus"
              class="my-0"
              @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;"
        >
          <template slot="actions" slot-scope="props">
            <va-button
              flat
              small
              color="gray"
              icon="fa fa-pencil"
              @click="edit(props.rowData)"
              class="ma-0">
            </va-button>
          </template>
          <template slot="action" slot-scope="props">
            <va-button
              flat
              small
              color="red"
              icon="fa fa-remove"
              @click="remove(props.rowData)"
              class="ma-0">
            </va-button>
          </template>
        </va-data-table>
        <va-modal
          v-model="showRemoveModal"
          title="Delete Service"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deleteService(entity)"
          @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Name</span>
            <va-input
              v-model.trim="name"
              type="text"
              v-if="isCreate || isUpdate"
              placeholder="Enter your service name"
              :error="!!NameErrors.length"
              :error-messages="NameErrors"
            />
            <div>
              <span v-if="isCreate" class="va-form-label va-form-required-label">Image (306px X 170px)</span>
              <span v-if="isUpdate" class="va-form-label">Image (306px X 170px)</span>
              <div class="row">
                <va-file-upload
                  v-if="isCreate || isUpdate"
                  type="single"
                  class="mt-2 ml-3"
                  @input="CheckFile('horizontal')"
                  v-model="image_file"
                />
                <div v-if="isUpdate">
                  <div class="scroll_overflow">
                    <div class="ml-4 mt-2" v-if="oldImage">
                      <div><img :src=image_url height="80px" width="80px" /></div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="isCreate || isUpdate"
                  class="imagePreviewWrapper mt-1 ml-2"
                  :style="{ 'background-image': `url(${previewImage})` }">
                </div>
              </div>
            </div>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createService()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateService()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
	  	name: 'app_setting',
	  	beforeCreate () {
	    	const loader = Vue.$loading.show({ width: 40, height: 40 })
	    	this.$http.get(config.menu.host + '/service_meta').then(response => {
	        	loader.hide()
	        	this.service_list = response.body
	        	this.isshowgrid = true
	    	}, error => {
	        	loader.hide()
	       	 	if (error && error.body) {
	          		Vue.notify({ text: error.body, type: 'error' })
	        	}
	    	})
	  	},
	  	data () {
	    	return {
	      		term: null,
	      		perPage: '10',
	      		perPageOptions: ['4', '6', '10', '20'],
	      		service_list: [],
	      		name: '',
	      		NameErrors: [],
	      		image_file: '',
	      		previewImage: null,
	      		img: '',
	      		oldImage: false,
	      		isshowgrid: true,
	      		isshowForm: false,
	      		isCreate: false,
	      		isUpdate: false,
	      		showRemoveModal: false,
	      		msg: '',
	    	}
	  	},
	  	computed: {
	  		CheckFile_upload () {
		        if (this.image_file[0]) {
		          	return true
		        } else {
		          	Vue.notify({ text: 'Please Upload images', type: 'error' })
		          	return false
		        };
		    },
	    	formReady () {
	      		return !this.NameErrors.length
	    	},
	    	fields () {
	      		return [{
	        		name: 'type',
	        		title: 'Type',
	      		},
	      		{
	        		name: 'name',
	        		title: 'Name',
	      		},
	      		{
	        		name: '__slot:actions',
	        		dataClass: 'text-right',
	      		},
	      		{
	        		name: '__slot:action',
	        		dataClass: 'text-right',
	      		}]
	    	},
	    	filteredData () {
	      		return search(this.term, this.service_list)
	    	},
	  	},
	  	methods: {
		    getService () {
		      	const loader = Vue.$loading.show({ width: 40, height: 40 })
		     	this.$http.get(config.menu.host + '/service_meta').then(response => {
		          	loader.hide()
		          	this.service_list = response.body
		          	this.isshowgrid = true
		        }, error => {
		          	loader.hide()
		          	if (error && error.body) {
		            	Vue.notify({ text: error.body, type: 'error' })
		          	};
		        })
		    },
		    CheckFile (type) {
		        if (this.image_file[0] == undefined || this.image_file.length == 0) {
		          	this.previewImage = null
		        }
		        if (typeof (type) !== 'undefined') {
		          	var image_file
				          	if (type == 'horizontal') {
				            	image_file = this.image_file[this.image_file.length - 1]
				          	}
				          	if ((type == 'horizontal') && (image_file !== undefined)) {
				            	var file_name = image_file.name.split('.').pop()
				            	if ((file_name.toLowerCase() === 'png') || (file_name.toLowerCase() == 'jpg') || (file_name.toLowerCase() == 'jpeg')) {
				              		const img = new Image()
				              		const width = '306'
				              		const height = '170'
				              		this.image_name = image_file.name
				              		const reader = new FileReader()
				              		reader.readAsDataURL(image_file)
				              		reader.onload = evt => {
					                		if (type == 'horizontal') {
					                  			this.previewImage = evt.target.result
					                  			this.oldImage = false
					                		}
					                		img.onload = () => {
						                  			if (img.width == width && img.height == height) {
						                    			if (type == 'horizontal') {
						                      				this.image_name = image_file.name
						                    			}
						                  			} else {
						                    			if (type === 'horizontal') {
						                      				this.image_file = ''
						                    			}
						                    			var flag = this.image_file ? 'horizontal' : 'banner'
						                    			if (flag === 'horizontal') {
						                      				this.image_file = []
						                    			}
						                    			if (this.image_file == '') {
						                      				this.previewImage = null
						                    			}
					                    			Vue.notify({ text: 'Please check the image size', type: 'error' })
							                  		}
					                		}
		                				img.src = evt.target.result
		              				}
		            		} else {
				              		if (type === 'horizontal') {
				                		this.image_file = ''
				                		Vue.notify({ text: 'Please check the image Format', type: 'error' })
				              		}
			            	}
		          	}
		        }
		    },
		    createService () {
		      	this.NameErrors = this.name ? [] : ['Name is required']
		      	if (!this.formReady) { return };
		      	if (!this.CheckFile_upload) { return };

		      	var Service = 'service'
		      	var payload = new FormData()
		    	payload.append('image_file', this.image_file[0])
		    	payload.append('image_file_name', this.image_name)
		    	payload.append('name', this.name)
		    	payload.append('type', Service)

		      	const loader = Vue.$loading.show({ width: 40, height: 40 })
		      	this.$http.post(config.menu.host + '/service_meta', payload).then(responseData => {
		          	loader.hide()
		          	Vue.notify({ text: responseData.body, type: 'success' })
		          	this.list()
		        }, err => {
		          	loader.hide()
		          	if (err && err.body) {
		            	Vue.notify({ text: err.body, type: 'error' })
		          	};
		        })
		    },
		    updateService () {
		      	this.NameErrors = this.name ? [] : ['Name is required']
		    	if (!this.formReady) { return };

		    	var Service = 'service'
		    	var payload = new FormData()
		    	payload.append('image_file', this.image_file[0])
		    	payload.append('image_file_name', this.image_name)
		    	payload.append('name', this.name)
		    	payload.append('type', Service)

		      	const loader = Vue.$loading.show({ width: 40, height: 40 })
		      	this.$http.put(config.menu.host + '/service_meta/' + this.service_meta_id, payload).then(responseData => {
		          	loader.hide()
		          	Vue.notify({ text: responseData.body, type: 'success' })
		          	this.list()
		        }, err => {
			        loader.hide()
		          	if (err && err.body) {
		            	Vue.notify({ text: err.body, type: 'error' })
		          	};
		        })
		    },
		    edit (row) {
		      	this.title = 'Update Service'
		      	this.service_meta_id = row.service_meta_id
		      	this.name = row.name
		      	this.NameErrors = []
		      	this.image_file = []
		      	this.image_url = row.image
		      	this.previewImage = ''
		      	this.isshowgrid = false
		      	this.isshowForm = true
		      	this.isCreate = false
		      	this.isUpdate = true
		      	this.oldImage = true
		    },
		    remove (row) {
		      	this.msg = 'Are you sure to delete the service ' + row.name + ' ?'
		      	this.entity = row
		      	this.showRemoveModal = true
		    },
		    cancel () {
		      	this.showRemoveModal = false
		    },
		    deleteService (data) {
		      	this.$http.delete(config.menu.host + '/service_meta/' + data.service_meta_id).then(resp => {
		        	Vue.notify({ text: resp.body, type: 'success' })
		        	this.list()
		      	}, err => {
		        	if (err && err.body) {
		        	  	Vue.notify({ text: err.body, type: 'error' })
		        	};
		      	})
		    },
		    add () {
		      	this.title = 'Create Service'
		      	this.name = ''
		     	this.NameErrors = []
		      	this.image_file = []
		      	this.previewImage = ''
		      	this.isshowForm = true
		      	this.isshowgrid = false
		      	this.isUpdate = false
		      	this.isCreate = true
		    },
		    list () {
		      	this.getService()
		      	this.isshowgrid = true
		      	this.isshowForm = false
		    },
		    search: function (term) {
		      	this.term = term
		    },
	  	},
}
</script>

<style>
</style>
